export enum Event {
  Clicked = 'clicked',
  ClickedDiscoverService = 'clicked-discover-service',
  ClickedFeaturedPost = 'clicked-featured-post',
  ClickedNextPost = 'clicked-next-post',
  ClickedRelatedPost = 'clicked-related-post',
  ClickedSearchResult = 'clicked-search-result',
  CookieOptinFirstScreen = 'cookie-optin-first_screen',
  CookieOptinOptionsScreenAllow = 'cookie-optin-options_screen_allow',
  CookieOptinOptionsScreenClose = 'cookie-optin-options_screen_close',
  DismissedFeaturedPost = 'dismissed-featured-post',
  FollowedUser = 'followed-user', // not used, only in BG
  NeedsAssessment = 'needs-assessment',
  ProfileSurvey = 'profile-survey',
  SawOopsPage = 'saw-oops-page',
  SearchedCommunity = 'searched-community',
  SearchedSite = 'searched-site',
  SignedUp = 'signed-up',
  SkippedFeaturedPost = 'skipped-featured-post',
  ViewedCommunity = 'viewed-community',
  ViewedFeaturedPost = 'viewed-featured-post',
  ViewedFeaturedPostUnique = 'viewed-featured-post-unique',
  ViewedNewsfeed = 'viewed-newsfeed',
  ViewedPage = 'viewed-page',
  ViewedPost = 'viewed-post',
  ViewedTagPage = 'viewed-tag-page',
  ViewedUser = 'viewed-user',
}

export const AnonymousEvents = [
  Event.Clicked,
  Event.ViewedPage,
];

export enum Section {
  AboutUs = 'about-us',
  Alerts = 'alerts',
  CampaignCreate = 'create-campaign',
  CampaignEdit = 'edit-campaign',
  Campaigns = 'campaigns-overview',
  Chat = 'chat',
  ChooseTopicModal = 'choose-topic-modal',
  CloseAccount = 'close-account',
  Communities = 'communities',
  CommunitiesJoin = 'communities-join',
  Community = 'Community',
  CommunityAbout = 'community-about',
  CommunityHome = 'community-home',
  CommunityHomePost = 'CommunityHomePost',
  CommunityHomeSideBar = 'community-home-side-bar',
  CommunityMembers = 'community-members',
  CommunityMetrics = 'community-metrics',
  CommunityPoll = 'community-poll',
  CommunityPosts = 'community-posts',
  CommunityPostsPoll = 'CommunityPostsPoll',
  CommunityPostsSideBar = 'community-posts-side-bar',
  CommunitySearch = 'community-search',
  CommunitySideBar = 'community-side-bar',
  CompletedProgram = 'completed-program',
  ConvBoxReply = 'ConvBoxReply',
  DiscoverSeeMore = 'discover-seemore',
  Edit = 'edit',
  EditProfile = 'editprofile',
  EmailConfirmationModal = 'email-confirmation-modal',
  Followers = 'followers',
  FollowersFollowings = 'followers-followings',
  Home = 'home',
  HuLogo = 'hu-logo',
  Join = 'join',
  Login = 'login',
  LoginModal = 'login-modal',
  ManageAll = 'manage-all',
  MarkSectionAsComplete = 'mark-section-as-complete',
  MemberCardProfileFollowers = 'member-card-profile-followers',
  MemberCardSpp = 'member-card-spp',
  Members = 'members',
  MobileShortcut = 'mobile-shortcut',
  More = 'more',
  MyHub = 'my-hub',
  NeedsAssessmentReport = 'needs-assessment-report',
  NewsFeedEmptyStateJoinCommunities = 'news-feed-empty-state-join-communities',
  NewsFeedFinishReadingPost = 'news-feed-finish-reading-post',
  NewsFeedLatestReply = 'news-feed-latest-reply',
  NewsFeedPost = 'news-feed-post',
  NewsFeedProfile = 'news-feed-profile',
  NewsFeedSidebar = 'news-feed-sidebar',
  NewsFeedWritePost = 'news-feed-write-post',
  NextPosts = 'Next Posts',
  Onboarding = 'onboarding',
  PartnersLearnMore = 'partners-learnmore',
  Post = 'Post',
  PostMore = 'post-more',
  PostReply = 'PostReply',
  PostShareWith = 'post-share-with',
  PostShareWithPrivacyInfo = 'post-share-with-privacy-info',
  PostsSideBar = 'posts-side-bar',
  PressLinks = 'press-links',
  ProfileAbout = 'profile-about',
  ProfileHeader = 'profile-header',
  ProfileNavbar = 'profile-navbar',
  ProfilePosts = 'profile-posts',
  ProfileReplies = 'profile-replies',
  Program = 'programe',
  PublicProfile = 'public-profile',
  Reply = 'reply',
  ReplyMore = 'reply-more',
  Resources = 'resources',
  SavedEmptyStateFindPost = 'saved-empty-state-find-post',
  SavedPost = 'saved-post',
  Search = 'search',
  SearchBar = 'search-bar',
  SearchCommunityCard = 'SearchCommunityCard',
  SearchTips = 'SearchTips',
  SecondPost = 'second-post',
  SeeResearch = 'see-research',
  StickyFeaturedPostFooter = 'sticky-featured-post-footer',
  StickyFooter = 'sticky-footer',
  SuggestedTags = 'suggested-tags',
  SuggestedTagsInfo = 'suggested-tags-info',
  Tag = 'Tag',
  TagsSeeAll = 'tags-seeall',
  TurnOffRepliesModal = 'turn-off-replies-modal',
  UnsubscribeAllAccepted = 'unsubscribe-all-accepted',
  UnsubscribeAllViewed = 'unsubscribe-all-viewed',
  UnsubscribePage = 'unsubscribe-page',
  Untracked = 'UNTRACKED',
  UserQuoteTestimonial = 'userquote-testimonial',
  UserSettings = 'user-settings',
  WritePostChooseCommunity = 'write-post-choose-community',
  WritePostTips = 'write-post-tips',
}
export enum Element {
  AskHealthQuestionLink = 'AskHealthQuestionLink',
  Avatar = 'Avatar',
  BasicInfo = 'BasicInfo',
  Button = 'Button',
  ContactUs = 'ContactUs',
  Edit = 'Edit',
  Email = 'Email',
  EnrollProgram = 'EnrollProgram',
  Facebook = 'Facebook',
  FindOthersLink = 'FindOthersLink',
  Follow = 'Follow',
  HealthInterests = 'HealthInterests',
  ImproveModule = 'improve-module',
  Join = 'join',
  Like = 'Like',
  Link = 'Link',
  Login = 'Login',
  MemberTab = 'Member-tab',
  MemberTag = 'member-tag',
  PollButton = 'poll-button',
  PollTitle = 'poll-title',
  ProgramModuleNextLink = 'ProgramModuleNextLink',
  ProgramModulePreviousLink = 'ProgramModulePreviousLink',
  ProgramSectionMarkAsComplete = 'ProgramSectionMarkAsComplete',
  ProgramSectionToggleSection = 'ProgramSectionToggleSection',
  PromoImage = 'PromoImage',
  QuestionMark = 'question-mark',
  RelatedPost = 'related-post',
  Reply = 'Reply',
  ReplyHiddenLink = 'ReplyHiddenLink',
  ReplySubmit = 'SubmitReply',
  Report = 'Report',
  SeeAllTopics = 'see-all-topics',
  SeeMoreRelatedPosts = 'see-more-related-posts',
  Signup = 'Signup',
  TakeHealthNeedsQuizLink = 'TakeHealthNeedsQuizLink',
  TextEditor = 'text-editor',
  Topic = 'topic',
  Untracked = 'UNTRACKED',
  UserLink = 'UserLink',
  VisitorPrompt = 'visitor-prompt',
  Vote = 'vote',
  Write = 'Write',
  WritePost = 'write-post',
}

export enum Origin {
  EditPostPage = 'edit-post-page',
  WritePostPage = 'write-post-page',
  EditPollPage = 'edit-poll-page',
  WritePollPage = 'write-poll-page',
}

export type FootfallContextType = {
  element?: Element;
  section: Section;
}
